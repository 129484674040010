import React from 'react';
import { Link, graphql } from 'gatsby';
import get from 'lodash/get';
import SEO from '../components/seo';
import Layout from '../components/layout.js';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';
import EventPreview from '../components/event-preview';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa';

import styles from './veranstaltungen.module.css';

class Events extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFutureEvents: false,
      offset: 0
    };
  }

  paginateArray(items, pageNumber, perPage) {
    const start = perPage * (pageNumber - 1)
    const size = items.length;
    return {
      data: items.slice(start, start + perPage),
      numberOfPages: Math.ceil(size / perPage),
      currentPage: pageNumber,
      dataLength: size
    }
  }

  render() {
    const page = get(this, 'props.data.contentfulSeite');
    const sponsors = get(this, 'props.data.allContentfulSponsor.edges');
    const events = get(this, 'props.data.allContentfulVeranstaltung.edges');
    const futureEvents = events.filter(({ node }) => new Date(node.endDate) > new Date());
    const pastEvents = events.filter(({ node }) => new Date(node.endDate) < new Date());

    pastEvents.sort((a, b) => {
      return new Date(b.node.endDate).getTime() - new Date(a.node.endDate).getTime();
    });

    return (
      <Layout location={this.props.location} sponsors={sponsors}>
        <SEO title={page.title} />
        <BackgroundImage backgroundColor="#0e3920" className="header" fluid={page.heroImage.fluid}>
          <h1>Veranstaltungen</h1>
        </BackgroundImage>
        {futureEvents.length > 0 &&
          <div className={styles.futureEventContainer}>
            <h2>Kommende Veranstaltungen</h2>
            {!this.state.showFutureEvents &&
              <div className={styles.previewContainer}>
                {futureEvents.slice(0, 4).map(({ node }) => (
                  <EventPreview key={node.slug} event={node} />
                ))}
              </div>
            }
            {this.state.showFutureEvents &&
              <div className={styles.previewContainer}>
                {futureEvents.map(({ node }) => (
                  <EventPreview key={node.slug} event={node} />
                ))}
              </div>
            }
            {futureEvents.length > 4 &&
              <button
                className={styles.eventPreviewButton}
                onClick={() => this.setState({ showFutureEvents: !this.state.showFutureEvents })}
              >
                {this.state.showFutureEvents ?
                  <React.Fragment>
                    <FaAngleUp size="1.4em" /> Weniger anzeigen
                  </React.Fragment>
                :
                  <React.Fragment>
                    <FaAngleDown size="1.4em" /> Alle anzeigen
                  </React.Fragment>
                }
              </button>
            }
          </div>
        }
        <div className="container">
          <div className={styles.pastEventContainer}>
            <h2>Chronik</h2>
            {pastEvents.slice(0, 6 + this.state.offset).map(({ node }, index) => (
              <Link key={index} className={styles.pastEventLink} to={`/veranstaltungen/${node.slug}`}>
                <article className={styles.pastEvent} key={node.name}>
                  {node.mainImage ? <Img className={styles.pastEventImage} fluid={node.mainImage.fluid} objectPosition="0% 50%" /> : <div className={styles.pastEventImage}></div>}
                  <div className={styles.pastEventTextContainer}>
                    <h3>{node.name}</h3>
                    <h4>{node.fromNow}</h4>
                    {node.description &&
                      <div className={styles.pastEventText}>
                        <article className="markdown" dangerouslySetInnerHTML={{
                          __html: node.description.childMarkdownRemark.excerpt,
                        }} />
                      </div>
                    }
                  <span className={styles.pastEventBtn}>Bericht öffnen</span>
                  </div>
                </article>
              </Link>
            ))}
            {this.state.offset + 6 < pastEvents.length &&
              <div className={styles.pagination}>
                <button onClick={() => this.setState({ offset: this.state.offset + 6 })}>Ältere Einträge laden</button>
              </div>
            }
          </div>
        </div>
      </Layout>
    )
  }
}

export default Events;

export const pageQuery = graphql`
  query EventQuery {
    contentfulSeite(slug: { eq: "veranstaltungen" }) {
      title
      heroImage {
        fluid(quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
    }
    allContentfulVeranstaltung(sort: { fields: startDate, order: ASC }) {
      edges {
        node {
          name
          slug
          startDate
          endDate
          formattedStartDate: startDate(formatString: "DD.MM.YYYY")
          fromNow: endDate(fromNow: true,  locale: "de-DE")
          location
          mainImage {
            fluid(quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
          description {
            childMarkdownRemark {
              excerpt(format: PLAIN, pruneLength: 150)
            }
          }
        }
      }
    }
    allContentfulSponsor(filter: { id: { ne: "10296087-115a-526e-9f65-04404f89693a" }}) {
      edges {
        node {
          name
          website
          logo {
            fixed(width: 250, quality: 100) {
              ...GatsbyContentfulFixed
            }
          }
        }
      }
    }
  }
`;
