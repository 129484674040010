import React from 'react';
import { Link } from 'gatsby';
import { FaMapMarkerAlt } from 'react-icons/fa';

import styles from './event-preview.module.css';

export default ({ event }) => {
  return (
    <Link className={styles.event} to={`/veranstaltungen/${event.slug}`}>
      <h4 className={styles.eventDate}>
        {event.formattedStartDate}
      </h4>
      <h3>{event.name}</h3>
      {event.location && <h4 className={styles.eventLocation}><FaMapMarkerAlt color="#2f612f" /><span>{event.location}</span></h4>}
    </Link>
  )
};
